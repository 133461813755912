

import { useEffect, useState, useRef } from 'react';
import datafeed from '../datafeed';


export const TVCahrt = () => {
    const [widget, setWidget] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        if(ref != null && ! widget) {
            const tvWidget = new window.TradingView.widget(
            {
                symbol: "BTC/USD",
                interval: "1D",
                library_path: 'charting_library/',
                container: "tv-chart-container",
                datafeed: datafeed,
            });    

            tvWidget.onChartReady = () => {
                console.log("tvchart: onChartReady.");
            }

            setWidget(tvWidget);
        }
    }, [ref, widget]);

    console.log("widget=", widget);
    
    return (<div ref={ref} id="tv-chart-container"></div>)
}