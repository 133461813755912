//import logo from './logo.svg';
import './App.css';
import { TVCahrt } from './components/TVChart';

function App() {
  
  return (
    <div className="App">
      <TVCahrt />
    </div>
  );
}

export default App;
